<template>
  <v-chip
    v-if="(entry.specialities && entry.specialities.length) || entry.type"
    size="small"
  >
    <span class="chip-content">
      {{ formatParts(specialityNames) }}
    </span>
    <span v-if="entry.specialities && entry.specialities.length && entry.type">&nbsp;|&nbsp;</span>
    {{ formatCareType(entry.type, '') }}
  </v-chip>
</template>

<script>
export default {
  props: {
    entry: {
      type: Object,
      required: true,
    },
  },
  computed: {
    specialityNames() {
      if (this.entry.specialities && this.entry.specialities) {
        return this.entry.specialities
          .filter((speciality) => speciality.speciality_id)
          .map((speciality) => speciality.speciality_id.name)
      } else {
        return []
      }
    },
  },
}
</script>

<style scoped>
.chip-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 150px;
}
</style>
